import classNames from 'classnames';
import { FC } from 'react';
import { Tag } from '~/types';

import styles from './TagButton.module.scss';

interface Props {
  tag: Tag;
  favoriteTagsIds: number[];
  setFavoriteTagsIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const TagButton: FC<Props> = ({ tag, favoriteTagsIds, setFavoriteTagsIds }) => {
  const onTagToggle = () => {
    setFavoriteTagsIds((prev) => {
      if (prev.includes(tag.id)) {
        return prev.filter((id) => id !== tag.id);
      } else {
        return [...prev, tag.id];
      }
    });
  };

  return (
    <button
      className={classNames(styles.button, {
        [styles.isActive]: favoriteTagsIds.includes(tag.id),
      })}
      onClick={onTagToggle}
      type='button'
    >
      {tag.title}
    </button>
  );
};

export default TagButton;
